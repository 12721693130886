<template>
  <section>
    <section id="container">
      <div id="loading-animation">
        <i style="font-size: 2rem; color: white" class="el-icon-loading"></i>
      </div>
    </section>
  </section>
</template>
  
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
let camera, scene, renderer;
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {};
  },
  mounted() {
    this.init();
    this.animate();
  },
  methods: {
    init() {
      const container = document.getElementById("container");
      camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.1,
        3000
      );
      renderer = new THREE.WebGLRenderer({ antialias: true });

      camera.position.set(0, 0, 80);

      scene = new THREE.Scene();

      renderer.setClearColor(new THREE.Color(0xf7f2f1));
      renderer.setSize(container.clientWidth, container.clientHeight);
      //   开启阻尼
      renderer.shadowMap.enabled = true;
      //   gltf颜色保真
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);
      // 创建背景纹理
      var textureLoader = new THREE.TextureLoader();
      var backgroundTexture = textureLoader.load(
        "../../assets/hall/banner.png"
      );
      // 设置场景背景
      scene.background = backgroundTexture;
      this.controls = new OrbitControls(camera, renderer.domElement);
      this.controls.target = new THREE.Vector3(0, 0, 0);
      window.addEventListener("resize", this.onWindowResize, false);
        this.loadLight();
      this.load3D();
    },
    load3D() {
    // 获取用于显示加载动画的元素
      const loadingAnimation = document.getElementById("loading-animation");
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://threejs.org/examples/jsm/libs/draco/"
      );
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        "https://oss.zijingshuke.com/1706144989600.glb",
        (gltf) => {
          scene.add(gltf.scene);
          renderer.render(scene, camera);
          this.mesh = gltf.scene;
          gltf.scene.position.set(-18, -25, 0);
          gltf.scene.scale.set(700, 700, 700);
          gltf.scene.rotation.set(0, 4.8, 0);
          // 隐藏加载动画
          loadingAnimation.style.display = "none";
        },
        (xhr) => {
           // 模型加载过程中的处理逻辑
           if (xhr.lengthComputable) {
            const loadedPercentage = (xhr.loaded / xhr.total) * 100;
            console.log(loadedPercentage + "% loaded");
          }
        },
        (error) => {
          console.error(error);
        }
      );
    },
    loadLight() {
      // 环境光
      const ambient = new THREE.AmbientLight(0xffffff);
      scene.add(ambient);
      const pointLight = new THREE.PointLight(0xffffff, 0.5);
      pointLight.position.set(100, 200, 500);
      pointLight.color.setHSL(255, 255, 255);
      scene.add(pointLight);
    },
    onWindowResize() {
     camera.aspect = window.innerWidth / window.innerHeight;
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.updateProjectionMatrix();
    },
   
    animate() {
      // //  使模型上下跳动
      // setTimeout(()=>{
      //   // console.log(this.mesh)
      //     const time = Date.now() * 0.002;
      //     this.mesh.position.y = Math.cos(time) * 2 - 20;
      // },100)
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },
  },
};
</script>
<style scoped>
.page {
  position: relative;
}

.nav {
  position: absolute;
  top: 20px;
  right: 20px;
}

#container {
  width: 100%;
  height: 100vh;
  /* height: calc(100vh); */
  margin: 0;
  overflow: hidden;
  background: url("../../assets/hall/banner.png") center no-repeat;
  background-size: cover;
}
#loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #000; */
  /* border-radius: 50%; */
  /* animation: spin 1s infinite linear; */
}
</style>

